import { withDependencies, multi, named } from '@wix/thunderbolt-ioc'
import {
	IAppWillLoadPageHandler,
	LifeCycle,
	BrowserWindow,
	BrowserWindowSymbol,
	FeatureStateSymbol,
	StructureAPI,
	IStructureAPI,
	MasterPageFeatureConfigSymbol,
	IPageWillUnmountHandler,
} from '@wix/thunderbolt-symbols'
import { IFeatureState } from 'thunderbolt-feature-state'
import { IPopups, PopupFeatureState, PopupsMasterPageConfig, PopupEvent, PopupEventListener } from './types'
import { name } from './symbols'
import { isSSR, enableCyclicTabbing, disableCyclicTabbing } from '@wix/thunderbolt-commons'
import { IPageProvider, PageProviderSymbol } from 'feature-pages'

type PopupsFactory = (
	appWillLoadPageHandlers: Array<IAppWillLoadPageHandler>,
	window: BrowserWindow,
	featureState: IFeatureState<PopupFeatureState>,
	structureAPI: IStructureAPI,
	masterPageConfig: PopupsMasterPageConfig,
	pageProvider: IPageProvider
) => IPopups

const popupsFactory: PopupsFactory = (
	appWillLoadPageHandlers,
	window,
	featureState,
	structureAPI,
	masterPageConfig,
	pageProvider
): IPopups => {
	const popupOpenEventListeners: Array<PopupEventListener> = []
	const popupCloseEventListeners: Array<PopupEventListener> = []
	let popupCloseHandler: PopupEventListener = null
	let propagatePageScroll: PopupEventListener

	const onKeyDown = (e: Event) => {
		const keyboardEvent = e as KeyboardEvent
		if (keyboardEvent.key === 'Escape') {
			closePopupPage()
		}
	}

	const closePopupPage = async () => {
		const popupPageId = getCurrentPopupId() as string
		const pageReflector = await pageProvider(popupPageId, popupPageId)
		const handlers = pageReflector.getAllImplementersOf<IPageWillUnmountHandler>(LifeCycle.PageWillUnmountHandler)
		await Promise.all(
			handlers.map((handler) => handler.pageWillUnmount({ pageId: popupPageId, contextId: popupPageId }))
		)

		disableCyclicTabbing()
		structureAPI.removeComponentFromDynamicStructure('POPUPS_ROOT')

		if (!isSSR(window)) {
			window.removeEventListener('keydown', onKeyDown)
		}
		if (popupCloseHandler) {
			popupCloseHandler()
			popupCloseHandler = null
		}
		if (popupCloseEventListeners.length > 0) {
			popupCloseEventListeners.forEach((eventHandler) => {
				if (eventHandler) {
					eventHandler()
				}
			})
		}
		featureState.update((state) => ({ ...state, pageWillLoadHandler: null, currentPopupId: undefined }))
	}

	const getCurrentPopupId = () => {
		return featureState.get() ? featureState.get().currentPopupId : undefined
	}

	return {
		isPopupPage(pageId) {
			return masterPageConfig.popupPages[pageId]
		},
		async openPopupPage(popupPageId, closeHandler = null) {
			enableCyclicTabbing()
			await Promise.all(
				appWillLoadPageHandlers.map((handler) =>
					handler.appWillLoadPage({ pageId: popupPageId, contextId: popupPageId })
				)
			)
			if (closeHandler) {
				popupCloseHandler = closeHandler
			}
			if (popupOpenEventListeners.length > 0) {
				popupOpenEventListeners.forEach((eventHandler) => {
					if (eventHandler) {
						eventHandler(popupPageId)
					}
				})
			}
			if (!isSSR(window)) {
				window.addEventListener('keydown', onKeyDown)
			}
			await structureAPI.addComponentToDynamicStructure('POPUPS_ROOT', {
				componentType: 'PopupRoot',
				components: [popupPageId],
			})
			featureState.update((state) => ({
				...state,
				pageWillLoadHandler: closePopupPage,
				currentPopupId: popupPageId,
			}))
		},
		closePopupPage,
		registerToPopupEvent(eventType: PopupEvent, eventHandler: PopupEventListener) {
			switch (eventType) {
				case 'popupScroll':
					propagatePageScroll = eventHandler
					const popupsRoot = window!.document.getElementById('POPUPS_ROOT')
					popupsRoot && popupsRoot.addEventListener('scroll', propagatePageScroll as EventListener)
					break
				case 'popupOpen':
					popupOpenEventListeners.push(eventHandler)
					break
				case 'popupClose':
					popupCloseEventListeners.push(eventHandler)
					break
				default:
					break
			}
		},
		getCurrentPopupId,
	}
}

export const Popups = withDependencies(
	[
		multi(LifeCycle.AppWillLoadPageHandler),
		BrowserWindowSymbol,
		named(FeatureStateSymbol, 'popups'),
		StructureAPI,
		named(MasterPageFeatureConfigSymbol, name),
		PageProviderSymbol,
	],
	popupsFactory
)
